<template>
  <CCard v-if="postData" class="socialwall mb-0" v-bind:class="{'preview' : preview === true}">
    <CCardBody class="pt-0 pb-0">

      <CRow v-if="postData.active === 'N'" class="post_inactive">
        <CCol class="p-0 text-center">
          {{$t('common.INACTIVE')}}
        </CCol>
      </CRow>

      <CRow v-if="postData.breaking_news == 1" class="breaking_news" :class="{'remove_top_radius' : postData.active === 'N'}">
        <CCol class="p-0 text-center">
          {{$t('common.BREAKING_NEWS')}} <i class="fas fa-exclamation-triangle"/>
        </CCol>
      </CRow>

      <CRow>
        <CCol class="meta" v-bind:class="{ 'pb-0' : (postData.post_type_tag === 'normal' && postData.post_uses_gallery === false && postData.image == null) ||
                                                    (postData.post_type_tag === 'normal' && postData.post_uses_gallery === true && postData.post_images_urls.length == 0) }">

          <div v-if="postData.user_is_system_account == 'Y'" class="profile_image mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/' + companyIdExternal + '/' + companyImageId + '.png' + '/' + clientToken + ')' }"></div>
          <div v-else-if="postData.user_is_system_account !== 'Y' && postData.sender">
            <userProfilePopover ref="userProfilePopover"
                                mode="sender"
                                :userName="postData.sender.sender_alias"
                                :userProfileImage="postData.sender.profile_image_id + '.jpg'"
                                :userIdExternal="postData.user_id_external"
                                :senderIdExternal="postData.sender.deliver_sender_id_external"
                                :popoverPlacement="preview === true ? 'bottom' : 'right'"
                                class="user_image mr-2"/>           
          </div>
          <div v-else>
            <userProfilePopover ref="userProfilePopover" mode="user" :userName="postData.posted_by_name" :userProfileImage="postData.profile_image" :userIdExternal="postData.user_id_external" :senderIdExternal="null" :popoverPlacement="preview === true ? 'bottom' : 'right'" class="user_image mr-2"/>
          </div>
          
          <div class="posted_by pt-0" v-bind:class="{ 'pb-0' : postData.image == null }">
            <span class="d-block">
              <b>{{postData.posted_by_name}}</b>
              <span v-if="postData.sender" class="ml-1">({{$t('connect.posted_as')}} {{postData.sender.sender_alias}})</span>
            </span>
            <span class="posted_in_at">
              <span class="d-flex align-items-center">
                <span class="material-icons mr-1">folder_open</span> 
                <span v-line-clamp="1">{{postData.sw_group_name}}</span>
              </span>           
              <span class="d-flex align-items-center ml-1">
                <span class="material-icons mr-1">alarm_add</span>
                <momentsAgo :date="postData.scheduled !== null ? postData.scheduled : postData.created"></momentsAgo>
              </span>
            </span>
          </div>
          <div class="post_actions pl-2">
            <span v-if="checkPermission('connect.sw.neweditpost') && editable === true" @click="openSidebarRight('sw_post_details', { post_id_external: postData.post_id_external, schedule_for_datetime: null, back_to_preview: preview ? true : false})" v-bind:class="{'mr-1' : previewable === true}"><i class="fas fa-pen"/></span>
            <span v-if="previewable === true" @click="openSidebarRight('sw_post_preview', { post_id_external: postData.post_id_external })"><i class="fas fa-search"/></span>
            <openExperience v-if="openedInExp" mode="icon" :experiencePath="'connect/post/' + postData.post_id_external" icon="external-link-alt" class="ml-1"/>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <div v-if="postData.post_type_tag === 'normal' && postData.post_uses_gallery === false && postData.image !== null" class="content_picture_column w-100">
          <CCol class="p-0">
            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/connect-post/' + postData.post_id_external + '/' + postData.image + '/' + clientToken" class="content_picture"/>
          </CCol>
        </div>
        <div v-else-if="postData.post_type_tag === 'normal' && postData.post_uses_gallery === true && postData.post_images_urls.length > 0" class="content_picture_column w-100">
          <CCol class="p-0">
            <div class="image_grid" v-bind:class="{'grid_1' : postData.post_images_urls.length == 1, 'grid_2' : postData.post_images_urls.length == 2, 'grid_3' : postData.post_images_urls.length >= 3}">

              <img v-if="postData.post_images_urls.length == 1" :src="postData.post_images_urls[0]" class="w-100 image_grid_background"/>

              <div v-for="(image_url, index) in postData.post_images_urls.slice(0, maxGridImages)" v-bind:key="index" v-bind:style="{ backgroundImage: 'url(' + image_url + ')' }" @click="openImageLightbox(postData.post_images_urls, index)" class="image_grid_item">
                <div v-if="index == maxGridImages - 1 && postData.post_images_urls.length > 3" class="grid_more h-100 w-100">
                  <span>{{postData.post_images_urls.length - maxGridImages}}+</span>
                </div>
              </div>
            </div>
            <CoolLightBox :items="lightboxImages" :index="lightboxActiveIndex" :slideshow="false" :enableScrollLock="true" @close="lightboxActiveIndex = null"></CoolLightBox>
          </CCol>
        </div>
        <div v-if="postData.post_type_tag === 'video'" class="content_video_column w-100">
          <div v-for="video in postData.post_videos.slice(0, 1)" v-bind:key="video.post_video_id">
            <div v-if="video.video_available === 'Y'">
              <div v-if="video.video_provider_tag === 'youtube'">
                <youtube :video-id="video.provider_video_id" ref="youtube" :player-vars="playerVars" :fitParent="true" class="w-100"></youtube>            
              </div>
              <div v-if="video.video_provider_tag === 'dyntube'" class="dyntube_iframe_container" v-bind:style="{ paddingTop: video.video_container_padding_percentage + '%' }">
                <iframe class="dyntube_responsive_iframe" webkitallowfullscreen mozallowfullscreen allowfullscreen :src="'https://play.dyntube.io/iframe/' + video.provider_video_private"></iframe>                      
              </div>
            </div>
            <videoPlaceholder v-else emoji="📹" :content="$t('common.Video_almost_available')"/>
          </div>
        </div>

        <div class="content_text_column w-100"
             v-bind:class="{'full_width' : (postData.post_type_tag === 'normal' && postData.post_uses_gallery === false && postData.image == null) ||
                                           (postData.post_type_tag === 'normal' && postData.post_uses_gallery === true && postData.post_images_urls.length == 0) }">
                              
          <CCol v-if="(postData.post_type_tag === 'normal' || postData.post_type_tag === 'video') && postData.content" class="content_text" v-bind:class="{'pb-0' : postData.content, 'p-0' : !postData.content}">
            <div v-if="preview === true">
              <p class="m-0" v-bind:class="{'scrollable' : scrollableContent === true}" v-html="postData.content"></p>            
            </div>
            <div v-else>
              <truncate class="content mb-0" action-class="read_more_btn" collapsed-text-class="collapsed" :length="maxContentLength" type="html" :text="postData.content"></truncate>
              <div v-if="postData.content.length > maxContentLength" class="read_more_link mt-3">
                <!-- <a v-if="previewable === true" @click="openPreview()">{{ $t('sw.read_more') }}</a> -->
                <a v-if="previewable" @click="openSidebarRight('sw_post_preview', { post_id_external: postData.post_id_external })">{{ $t('sw.read_more') }}</a>
                <a v-else-if="!previewable && checkPermission('connect.sw.postinsights')" @click="showPostInsights(postData.post_id_external)">{{ $t('sw.read_more') }}</a>
              </div>            
            </div>
          </CCol>
          
          <CCol v-if="postData.post_type_tag === 'vote' && postData.content" class="p-0 content_text social_vote">
            <div class="social_vote_content">
              <p class="m-0" v-html="postData.content"></p>
            </div>
          </CCol>

          <CCol v-if="postData.post_type_tag === 'vote'" class="p-3 pb-0 social_vote">
            <div class="d-flex align-items-center social_vote_buttons">
              <div class="vote_down">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="material-icons mr-1">thumb_down</span>
                  <span>{{socialVoteResult.down_votes}}</span>
                </div>             
              </div>
              <div class="flex-grow-1 text-center social_vote_count">
                <b>{{(socialVoteResult.up_votes - socialVoteResult.down_votes) | abbr}}</b>
              </div>
              <div class="vote_up">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="material-icons mr-1">thumb_up</span>
                  <span>{{socialVoteResult.up_votes}}</span>
                </div>  
              </div>
            </div>
          </CCol>

          <CCol v-if="modules.satisquestions === 1 && modules.social_wall_satisquestions == 1 && postData" class="p-0">
            <swPostQuestion :satisquestionData="postData.satisquestion"/>
          </CCol>

          <CCol class="content_buttons pt-0 pb-0">
            <div v-if="modules.social_kb == 1 && postData.kb_intent_id_external && postData.intent_reference" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openItem(postData.intent_reference)">
                <i class="fa-solid fa-lines-leaning button_icon"/>              
                <span v-line-clamp="1" class="button_text">{{postData.intent_reference.kb_intent_title}}</span>
              </CButton>            
            </div>
            <div v-if="modules.academy == 1 && postData.lms_course_id_external && postData.course_reference" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openCourse(postData.course_reference)">
                <i class="fa-solid fa-user-graduate button_icon"/>
                <span v-line-clamp="1" class="button_text">{{postData.course_reference.course_name}}</span>
              </CButton>
            </div>
            <div v-if="modules.employee_loyalty_programme == 1 && postData.loyalty_store_product_id_external && postData.product_reference" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openProduct(postData.product_reference)">
                <i class="fa-solid fa-gift button_icon"/>
                <span v-line-clamp="1" class="button_text">{{postData.product_reference.product_name}}</span>
              </CButton>
            </div>            
            <div v-if="postData.external_url" class="mt-2" @click="openExternalLink(postData.external_url)">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary">
                <i class="fa-solid fa-link button_icon"></i>             
                <span v-line-clamp="1" class="button_text">{{postData.external_url_name ? postData.external_url_name : $t('sw.external_url')}}</span>
              </CButton>              
            </div>
            <div v-if="postData.pdf_attachment" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openExternalLink(apiBaseUrl + '/v1/common/cdn/file/pdf/connect-post/' + postData.post_id_external + '/' + postData.pdf_attachment + '/' + clientToken)">
                <i class="fa-solid fa-paperclip button_icon"></i>
                <span v-line-clamp="1" class="button_text">{{postData.pdf_attachment_name ? postData.pdf_attachment_name : $t('sw.pdf_attachment')}}</span>
              </CButton>
            </div>
            <div v-if="apiBaseUrl && clientToken && postData.audio_attachment" class="mt-2">
              <audioPlayer :standalone="false"
                           :audioFileName="postData.audio_attachment_name"
                           :audioSourceUrl="apiBaseUrl + '/v1/common/cdn/file/mp3/connect-post/' + postData.post_id_external + '/' + postData.audio_attachment + '/' + clientToken">
              </audioPlayer>            
            </div>
          </CCol>
        </div>
      </CRow>

      <CRow v-if="modules.social_wall_post_actions === 1" class="pt-0 activity_bar">
        <CCol cols="8" sm="8" md="8" lg="8" class="post_likes">
          <div class="mr-2 type likes">
            <span class="mr-1" v-html="getTwemoji(postData.likes.emoji)"></span>
            <span>{{ postData.likes.likes }}</span>           
          </div>
          <div class="mr-2 type dislikes">
            <span class="mr-1" v-html="getTwemoji(postData.dislikes.emoji)"></span>
            <span>{{ postData.dislikes.dislikes }}</span>        
          </div>
          <div class="mr-2 type waves">
            <span class="mr-1" v-html="getTwemoji(postData.waves.emoji)"></span>
            <span>{{ postData.waves.waves }}</span>           
          </div>
          <div class="type hearts">
            <span class="mr-1" v-html="getTwemoji(postData.hearts.emoji)"></span>
            <span>{{ postData.hearts.hearts }}</span>
          </div>
        </CCol>
        <CCol cols="4" sm="4" md="4" lg="4" class="post_reactions text-right">
          <a @click="!checkPermission('connect.sw.postinsights') ? showPostInsights(postData.post_id_external) : null">
            <span v-if="preview === false || (preview === true && postData.disable_reactions === false)" class="position-relative">
              <span class="material-icons d-inline-block align-middle">chat_bubble_outline</span>
              <i v-if="postData.disable_reactions === true" class="fas fa-slash"></i>    
              <span v-if="postData.disable_reactions === false" class="ml-1">{{postData.total_replies}}</span>
            </span>
          </a>
        </CCol>
      </CRow>

      <hr v-if="modules.social_wall_post_actions === 1 && showReplies" style="margin: 0px -0.75rem;"/>

      <CRow v-if="modules.social_wall_post_actions === 1 && showReplies">
        <CCol cols="12" sm="12" md="12" lg="12">
          <div v-if="postData.disable_reactions === false" class="w-100">
            <div id="postRepliesContainer" class="replies_container" v-bind:class="{'p-0' : postReplies.length == 0}">
              <div v-if="postReplies.length > 0" class="replies">
                <swPostReply v-for="reply in postReplies"
                            v-bind:key="reply.post_reply_id"
                            :reply="reply"
                            :showChildReplies="true"
                            :modules="modules"
                            :showLikes="true"/>
              </div>
              <div v-else class="no_replies w-100">
                <span>{{ $t('sw.no_reactions') }}</span>
              </div>
            </div>
            <!-- <div class="new_reply">
              <div class="w-100">                
                <CTextarea v-bind:placeholder="$t('sw.your_reply')" v-model="newReply.content" rows="4"/>
                <CButton color="primary" @click="postReply(postData.post_id_external);" :disabled="newReply.content.length == 0" class="w-100 m-0">
                  <span>{{ $t('sw.post_reply') }}</span>
                  <span class="material-icons align-middle ml-1" style="font-size:14px;">reply</span>
                </CButton>
              </div>
            </div> -->
          </div>
          <div v-else class="reactions_disabled_notice">
            <span class="position-relative">
              <span class="material-icons d-inline-block align-middle">chat_bubble_outline</span>
              <i v-if="postData.disable_reactions === true" class="fas fa-slash"></i>    
              <span v-if="postData.disable_reactions === false" class="ml-1">{{postReplies.length}}</span>
            </span>
            <span class="ml-1">{{ $t('sw.replies_disabled') }}</span>
          </div>
        </CCol>
      </CRow>

      <div v-if="showInsights === true && checkPermission('connect.sw.postinsights')" :class="{'pt-2_5' : modules.social_wall_post_actions === 0}">
        <hr style="margin: 0px -0.75rem;"/>
        <CRow>
          <CCol cols="12" sm="12" md="12" lg="12">
            <div class="d-flex post_insights" @click="showPostInsights(postData.post_id_external)">
              <div class="flex-fill">
                <div class="d-flex">
                  <span class="mr-2 d-flex align-items-center">
                    <i class="icon top fas fa-tachometer-alt mr-1"/>{{postData.insights.engagement_score}}
                  </span>
                  <span class="mr-2 d-flex align-items-center">
                    <i class="icon top fas fa-percentage mr-1"/>{{postData.insights.reach.percentage}}
                  </span>
                  <span class="mr-2 d-flex align-items-center">
                    <i class="icon top far fa-eye mr-1"/>{{postData.insights.reach.count.views}}
                  </span>
                  <span class="mr-2 d-flex align-items-center">
                    <i class="icon top far fa-user mr-1"/>{{postData.insights.reach.count.unique_views}}
                  </span>
                </div>
              </div>
              <div>
                <i class="icon top fas fa-chevron-right justify-content-end"/>
              </div>
            </div>
          </CCol>
        </CRow>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'
import truncate from 'vue-truncate-collapsed';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import videoPlaceholder from '@/components/common/videoPlaceholder.vue';
import momentsAgo from "@/components/common/momentsAgo.vue";
import openExperience from "@/components/common/openExperience.vue";
import swPostReply from "@/components/connect/swPostReply.vue";
import audioPlayer from '@/components/common/audioPlayer.vue';

import swPostQuestion from "@/components/connect/swPostQuestion.vue";

export default {
  name: 'swPostCard',
  props: ['postData', 'modules', 'platformPermissions', 'navigatable', 'editable', 'previewable', 'preview', 'showReplies', 'showInsights', 'scrollableContent', 'openedInExp'],
  components: {
    truncate,
    userProfilePopover,
    loadingSpinner,
    videoPlaceholder,
    momentsAgo,
    openExperience,
    swPostReply,
    audioPlayer,
    swPostQuestion
  },
  watch: {
    $props: {
      handler() {      
        // Get the social vote results for Social Vote posts
        if(this.postData && this.postData.post_type_tag === 'vote') this.getSocialVoteResults(this.postData.post_id_external);
        // Get the post replies if necessary
        if(this.showReplies) this.getPostReplies(this.postData.post_id_external);        
      },
      deep: true,
      immediate: true,
    }
  },    
  data() {
    return {
      appBaseUrl: null,
      apiBaseUrl: null,
      clientToken: null,      
      companyIdExternal: null,
      companyImageId: null,
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      },
      maxGridImages: 3,
      maxContentLength: 200,
      maxCount: 250,
      lightboxImages: [],
      lightboxActiveIndex: null,
      newReply: {
        content: ''
      },
      postReplies: [],     
      socialVoteResult: {
        total_votes: 0,
        up_votes: 0,
        down_votes: 0,
        voted_by_user: true
      }      
    } 
  },
  filters: {
    abbr: function(points) {
      if(String(points).length >= 7) {
        if(points % 1 != 0) {
          return (points/1000000).toFixed(1) + 'M';
        } else {
          return (points/1000000) + 'M';
        }
      } else if(String(points).length >= 4) {
        if(points % 1 != 0) {
          return (points/1000).toFixed(1) + 'K';
        } else {
          return (points/1000) + 'K';
        }        
      } else {
        return points;
      }
    }
  },  
  methods: {
    getPostReplies(postIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/replies/post/' + postIdExternal)
      .then(res => {
        this.postReplies = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });  
    },   
    openImageLightbox(images, index) {
      // Set the images
      this.lightboxImages = images;
      // Set the active index
      this.lightboxActiveIndex = index;
    },
    // postReply (postIdExternal) {
    //   let params = {};
    //   params.content = this.newReply.content;
      
    //   let content = params.content;

    //   if(content) {
    //     if(content.length <= this.maxCount) {
    //       axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/reply/post/' + postIdExternal, params)
    //       .then(res => {
    //         // Emit the sw_reply_posted event
    //         this.$bus.$emit('sw_reply_posted', postIdExternal);
    //         // Reset the data
    //         this.resetNewReply();
    //       })
    //       .catch(err => {
    //         console.error(err); 
    //       });
    //     } else {
    //       this.$buefy.toast.open({ message: this.$t('common.Your_reply_is') + ' ' + (content.length - this.maxCount) + ' ' + this.$t('common.characters_too_long'), type: 'is-danger', duration: 2000 });
    //     }
    //   } else {
    //     this.$buefy.toast.open({ message: this.$t('common.Empty_reply'), type: 'is-danger', duration: 2000 });
    //   }
    // },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    showPostInsights(id) {
      if(this.navigatable) this.$router.push({path: `/insights/connect/post/${id.toString()}`});
    },
    openItem(data) {
      window.open(this.appBaseUrl + '/knowledge/library/item/' + data.kb_intent_id_external, '_blank');            
    },
    openCourse(data) {
      window.open(this.appBaseUrl + '/learn/catalog/course/' + data.lms_course_id_external, '_blank');      
    },
    openProduct(data) {
      window.open(this.appBaseUrl + '/loyalty/store/product/' + data.loyalty_store_product_id_external, '_blank');      
    },    
    openExternalLink(url) {
      window.open(url, '_blank');
    },
    resetNewReply() {
      this.newReply = { content: '' }
    },
    getSocialVoteResults(postIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/votes/' + postIdExternal)
      .then(res => {
        this.socialVoteResult = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.appBaseUrl = process.env.VUE_APP_URL;
    this.clientToken = localStorage.getItem('token');    
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');

    this.resetNewReply();

    this.$bus.$on('sw_reply_deleted', (postIdExternal) => {      
      this.getPostReplies(postIdExternal);
    })
  },
  beforeDestroy() {
    this.$bus.$off('sw_reply_deleted');    
  }
}
</script>