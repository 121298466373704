<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{group.group_id_external === null || group.group_id_external === undefined ? $t('groups.New_target_group') : $t('groups.Edit_target_group')}}          
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <div class="group_details">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">            
              <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="group.title">                        
                <h2 class="m-0">{{group.title ? group.title : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
              </quick-edit>
            </CCol>
          </CRow>
          <CRow class="m-0">           
            <CCol cols="12" lg="12" class="pt-0">
              <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="group.description">
                <span>{{group.description ? group.description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
              </quick-edit>            
            </CCol>            
          </CRow>
          <CRow v-if="checkRole('super_admin')" class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <b-switch class="admin mb-0" v-model="group.is_editable" size="is-small">{{ $t('groups.Is_editable') }}</b-switch>
            </CCol>
          </CRow>           
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label>{{ $t('groups.Filters') }}</label>
              <CRow v-for="(filter, index) in group.filters" v-bind:key="index">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <div class="mb-2 d-flex filters">
                    <div>
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.type" 
                                   :options="groupFilterTypes" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_filter_type')" 
                                   :selectLabel="$t('common.Add_filter_type')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_filter_type')"
                                   track-by="type_tag" 
                                   label="type_name"
                                   :custom-label="customFilterTypeLabel"
                                   :disabled="filter.type !== null && filter.type !== undefined"
                                   @input="setupTypeFilter(filter.type.type_tag, index);">
                        <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>

                    <div v-if="filter.type" class="ml-2" v-bind:class="{'mr-2' : filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)}">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.condition" 
                                   :options="typeFilterConditions" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_filter_condition')" 
                                   :selectLabel="$t('common.Add_filter_condition')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_filter_condition')"
                                   track-by="condition_tag" 
                                   label="condition_name"
                                   :custom-label="customFilterConditionLabel"
                                   :disabled="filter.condition !== null && filter.condition !== undefined"
                                   @input="filter.condition && conditionsWithoutValue.includes(filter.condition.condition_tag) ? getGroupMembers() : null;">
                        <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>

                    <div v-if="filter.type && filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)">
                      <div v-if="filter.type.type_tag == 'department'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterDepartments" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_department')"
                                     :selectLabel="$t('common.Add_department')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_department')"
                                     track-by="department_id" 
                                     label="department_name"
                                     @search-change="asyncFindDepartment"
                                     @input="getGroupMembers();">
                          <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>
                      <div v-if="filter.type.type_tag == 'team'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterTeams" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_team')" 
                                     :selectLabel="$t('common.Add_team')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_team')"
                                     track-by="team_id" 
                                     label="team_name"
                                     @search-change="asyncFindTeam"
                                     @input="getGroupMembers();">
                          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>
                      <div v-if="filter.type.type_tag == 'user'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterUsers" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_user')" 
                                     :selectLabel="$t('common.Add_user')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_user')"
                                     track-by="user_id_external" 
                                     label="label"                        
                                     @search-change="asyncFindUser"
                                     @input="getGroupMembers();">
                          <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>  
                      </div>
                      <div v-if="filter.type.type_tag == 'department_manager'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterDepartmentManagers" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Set_manager')" 
                                     :selectLabel="$t('common.Select_manager')" 
                                     :selectedLabel="$t('Selected')"
                                     :deselectLabel="$t('common.Deselect_manager')"
                                     track-by="user_id_external" 
                                     label="label"                        
                                     @search-change="asyncFindDepartmentManager"
                                     @input="getGroupMembers();">
                          <span slot="noResult">{{ $t('common.No_managers_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>                               
                      <div v-if="filter.type.type_tag == 'team_manager'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterTeamManagers" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Set_employee')" 
                                     :selectLabel="$t('common.Select_employee')" 
                                     :selectedLabel="$t('Selected')"
                                     :deselectLabel="$t('common.Deselect_employee')"
                                     track-by="user_id_external" 
                                     label="label"                        
                                     @search-change="asyncFindTeamManager"
                                     @input="getGroupMembers();">
                          <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>
                      <div v-if="filter.type.type_tag == 'target_group'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterTargetGroups" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_group')" 
                                     :selectLabel="$t('common.Add_group')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_group')"
                                     track-by="group_id" 
                                     label="title"
                                     @search-change="asyncFindTargetGroup"
                                     @input="getGroupMembers();">
                          <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>  
                      </div>
                      <div v-if="['date_started', 'date_left'].includes(filter.type.type_tag)">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="typeFilterValues" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_filter_value')" 
                                     :selectLabel="$t('common.Add_filter_value')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_filter_value')"
                                     track-by="type_value_tag" 
                                     label="type_value_name"
                                     :custom-label="customFilterTypeValueLabel"
                                     @input="getGroupMembers();">
                          <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                        </multiselect>                        
                      </div>
                      <div v-if="userAttributes.includes(filter.type.type_tag)">
                        <CInput v-debounce:1s="getGroupMembers"
                                type="text" 
                                class="attribute mb-0"
                                v-model="filter.value">
                        </CInput>
                      </div>                   
                    </div>

                    <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(group, index);">
                      <i class="fas fa-times"/>
                    </CButton>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <CButton color="primary" @click="addFilterOption();">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{ $t('common.Add_filter') }}</span>
                  </CButton>
                </CCol>
              </CRow>                    
            </CCol>
          </CRow>
        </div>
        <hr class="m-0">
        <div class="group_members">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="text-left" v-bind:class="{'pb-0' : !listViewEnabled}">
              <span class="sidebar_subject">
                {{ $t("groups.Target_group_members") }} <span v-if="groupMembers.length > 0">({{groupMembers.length}})</span>
              </span>
            </CCol>
            <CCol cols="6" lg="6" class="text-right" v-bind:class="{'pb-0' : !listViewEnabled}">
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
            </CCol>
          </CRow>          
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                    <b-table ref="groupTable" class="data_table" :data="groupMembers" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                      <template slot-scope="props">
                        <b-table-column field="name" :label="$t('common.Name')">
                          <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                            <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>                            
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </div>
                        </b-table-column>
                        <b-table-column field="department_name" :label="$t('common.Department')">
                          <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="team_name" :label="$t('common.Team')">
                          <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="function" :label="$t('common.Function')">
                          <span>{{props.row.function !== null && props.row.function !== '' ? props.row.function : '-'}}</span>
                        </b-table-column>
                      </template>
                      <template slot="empty">
                        <div class="text-center">
                          <div v-if="group.filters.length > 0">
                            {{ $t('groups.No_users_based_on_filters') }}
                          </div>
                          <div v-else>
                            {{ $t('groups.Add_filters_to_show_members') }}
                          </div>
                        </div>                
                      </template>                
                    </b-table>                    
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <div v-if="groupMembers.length > 0">
                  <CRow class="group_member_container tile-container m-0">
                    <CCol cols="4" xl="4" lg="4" md="4" sm="4" v-for="member in currentPageMembers[currentMemberPage - 1]" v-bind:key="member.user_id_external" class="member_column p-0">
                      <userProfileCard :userData="member" @click.native="openSidebarRight('user_profile', { user_id_external:  member.user_id_external });" class="member pointer"/>
                    </CCol>
                  </CRow>
                  <hr v-if="groupMembers.length > membersPerPage" class="m-0">
                  <CRow v-if="groupMembers.length > membersPerPage" class="m-0">
                    <CCol cols="12" md="12">  
                      <v-pagination class="justify-content-end" v-model="currentMemberPage" :length="memberPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                    </CCol>
                  </CRow>
                </div>
                <div v-else>
                  <CRow class="m-0">
                    <CCol cols="12" lg="12" class="pt-0">
                      <div v-if="group.filters.length > 0">
                        {{ $t('groups.No_users_based_on_filters') }}
                      </div>
                      <div v-else>
                        {{ $t('groups.Add_filters_to_show_members') }}
                      </div>
                    </CCol>
                  </CRow>                  
                </div>
              </div>
            </CCol>                      
          </CRow>             
        </div>        
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="group.group_id_external === null || group.group_id_external === undefined">
              <CButton color="primary" @click="insertGroup();"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateGroup(group);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>      
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

import userProfileCard from "@/components/common/userProfileCard.vue";
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'targetGroup',
  props: ['history', 'groupIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    userProfileCard,
    userProfileAvatar
  },
  watch: {
    $props: {
      handler() {
        this.getGroupDetails();
        this.getPlatformRoles();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      platformRoles: [],
      platformRolesLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      group: {
        filters: []
      },
      groupMembers: [],
      groupFilterTypes: [],
      groupFilterConditions: [],
      typeFilterConditions: [],
      groupFilterValues: [],
      groupFilterTargetGroups: [],      
      groupFilterUsers: [],
      groupFilterDepartmentManagers: [],
      groupFilterTeamManagers: [],      
      groupFilterDepartments: [],
      groupFilterTeams: [],
      typeFilterValues: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service', 'days_of_service'],
      conditionsWithoutValue: ['is_not_set', 'is_today'],
      currentMemberPage: 1,
      currentPageMembers: {},
      membersPerPage: 9,
      memberPages: 0 
    }
  },
  methods: {
    getGroupDetails() {
      if(this.groupIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/' + this.groupIdExternal)
        .then(res => {
          this.group = res.data.data;
          // Get the group members
          this.getGroupMembers();
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the group data
        this.resetGroupData();
      }
    },
    insertGroup() {
      let _this = this;
      let params = {};
      params = this.group;
      (params.is_editable === true) ? params.editable = 'Y' : params.editable = 'N';

      params.filters = this.group.filters.filter(function (el) {
        return el.type !== undefined && 
               el.condition !== undefined && (
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === false && el.value !== undefined) || 
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === true)
               );
      });

      let title = params.title;    

      if(title) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('groups.Target_group_added'), type: 'is-success', duration: 2000 });
          // Emit the update_groups event
          this.$bus.$emit('update_groups');
          // Reset the group data
          this.resetGroupData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    updateGroup(data) {
      let _this = this;      
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      (params.is_editable === true) ? params.editable = 'Y' : params.editable = 'N';
      
      params.filters = data.filters.filter(function (el) {
        return el.type !== undefined && 
               el.condition !== undefined && (
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === false && el.value !== undefined) || 
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === true)
               );
      });      

      let title = params.title;     

      if(title) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/' + this.groupIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('groups.Target_group_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_group_details event
          this.$bus.$emit('update_group_details', this.groupIdExternal);          
          // Get the group details
          this.getGroupDetails(this.groupIdExternal);
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    getGroupMembers() {
      let _this = this;
      let params = {};
      params.use_priority = false;
      
      params.filters = this.group.filters.filter(function (el) {
        return el.type !== undefined && 
               el.condition !== undefined && (
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === false && el.value !== undefined) || 
                (_this.conditionsWithoutValue.includes(el.condition.condition_tag) === true)
               );
      });
      
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/members', params)
      .then(res => {
        this.groupMembers = res.data.data;
        // Enable the pagination if necessary
        (this.groupMembers.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Set the pagination to the first page
        this.currentMemberPage = 1;
        // Reset the memberPages value
        this.memberPages = 0;
            
        for (let i = 0; i < this.groupMembers.length; i = i + this.membersPerPage) {
          this.currentPageMembers[this.memberPages] = this.groupMembers.slice(i, i + this.membersPerPage);
          this.memberPages++;
        }
      })
      .catch(err => {
        console.error(err); 
      });     
    },        
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {
        this.groupFilterTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterConditions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag, filterIndex) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
      // Auto set the condition if the filter type has only one available
      if(this.typeFilterConditions.length === 1) {
        this.group.filters[filterIndex].condition = this.typeFilterConditions[0];      

        if(this.conditionsWithoutValue.includes(this.group.filters[filterIndex].condition.condition_tag)) {
          this.getGroupMembers();
        }
      }      
    },   
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.groupFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    setupTypeFilter(filterTypeTag, filterIndex) {
      // Get the filter type conditions
      this.getTypeFilterConditions(filterTypeTag, filterIndex);
      // Get the filter type values
      this.getTypeFilterValues(filterTypeTag);
      // Get the filter values
      if(filterTypeTag === 'department') {
        this.getGroupFilterDepartments();        
      } else if(filterTypeTag === 'team') {
        this.getGroupFilterTeams();        
      } else if(filterTypeTag === 'user') {
        this.getGroupFilterRecipients();        
      } else if(filterTypeTag === 'department_manager') {
        this.getGroupFilterDepartmentManagers();        
      } else if(filterTypeTag === 'team_manager') {
        this.getGroupFilterTeamManagers();        
      } else if(filterTypeTag === 'target_group') {
        this.getGroupFilterTargetGroups();        
      }      
      // Force update
      this.$forceUpdate();
    },    
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },
    addFilterOption() {
      this.group.filters.push({});
    },
    removeFilterOption(data, index) {
      let groupFilterIdExternal = data.filters[index].group_filter_id_external;
      
      if(groupFilterIdExternal !== null && groupFilterIdExternal !== undefined) {
        axios.delete(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/' + this.groupIdExternal + '/filter/' + groupFilterIdExternal)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('groups.Filter_removed'), type: 'is-success', duration: 2000 });
          this.getGroupDetails(this.groupId);
          this.group.filters.splice(index, 1);
        })
        .catch(err => {
          // console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.group.filters.splice(index, 1);
        this.getGroupMembers();
      }
    },
    resetGroupData () {
      this.group = {
        title: '',
        description: '',
        filters: [],
        is_editable: false
      }
    },
    getGroupFilterTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.groupFilterTargetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterRecipients() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/multiselect')
      .then(res => {
        this.groupFilterRecipients = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterDepartmentManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/department-managers/multiselect')
      .then(res => {
        this.groupFilterDepartmentManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeamManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/team-managers/multiselect')
      .then(res => {
        this.groupFilterTeamManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/multiselect')
      .then(res => {
        this.groupFilterDepartments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeams() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/teams/multiselect')
      .then(res => {
        this.groupFilterTeams = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },          
    asyncFindUser(query) {
      let searchTerm = query;
      this.groupFilterUsers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.groupFilterUsers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindDepartmentManager(query) {
      let searchTerm = query;
      this.groupFilterDepartmentManagers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/department-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartmentManagers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeamManager(query) {
      let searchTerm = query;
      this.groupFilterTeamManagers = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/team-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterTeamManagers = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },            
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
        // Update the platformRolesLoaded value
        this.platformRolesLoaded = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();
  }
}
</script>